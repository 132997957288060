import { SlIcon, SlButton, SlIconButton } from '@shoelace-style/shoelace/dist/react';
import { Fragment } from 'react';

interface ActionIconButton {
  label?: string;
  iconBtn: true;
  icon: { name: string; library: string; size?: number };
  className?: string;
  onClick: () => void;
  show?: boolean;
}

interface ActionButton {
  label: string;
  iconBtn?: false;
  icon?: { name: string; library: string; size?: number };
  className?: string;
  onClick: () => void;
  show?: boolean;
}

interface ActionButtonsProps {
  actions: Array<ActionButton | ActionIconButton>;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ actions }) => {
  return (
    <div className="action-buttons">
      {actions?.map((v, i) =>
        v?.show === false ? (
          <Fragment key={i} />
        ) : v.iconBtn === true ? (
          <SlIconButton
            key={i}
            name={v.icon.name}
            library={v.icon.library}
            style={{ fontSize: v.icon.size }}
            onClick={v.onClick}
            className={v.className}
          />
        ) : (
          <SlButton key={i} variant="text" onClick={v.onClick} className={v.className}>
            {v.icon && <SlIcon name={v.icon.name} library={v.icon.library} style={{ fontSize: v.icon.size }} />}
            {v.label}
          </SlButton>
        ),
      )}
    </div>
  );
};
