import { deepCompare } from '@co4/graph';
import { memo } from 'react';
import { getNestedValue } from '../utils';
import { ColumnData } from './Table';

interface TableCellProps {
  cell: ColumnData;
  index: number;
  data: Record<string, string | number>;
}

const TableCell: React.FC<TableCellProps> = (props) => {
  const { cell, index, data } = props;
  return (
    <td data-value={getNestedValue(data, cell.field)}>
      {cell.renderer
        ? cell.renderer({ ...data, rowValue: getNestedValue(data, cell.field), index })
        : getNestedValue(data, cell.field) ?? '-'}
    </td>
  );
};

export default memo(TableCell, deepCompare);
