import { useEffect, useMemo, useState } from 'react';
import { currentLanguage$, getLanguage } from '../language';
import { apps$ } from '../apps';
import { Co4App, SystemObject } from '../types';

export function useCurrentApps(current: SystemObject) {
  const [apps, setApps] = useState<Array<Co4App>>([]);

  useEffect(() => {
    const sub = apps$.subscribe((apps) => {
      setApps(apps);
    });

    return () => sub.unsubscribe();
  }, []);

  const currentApps = useMemo(() => {
    const compatibleApps = apps.filter((item) => {
      const reqd = item.options.requires(current);
      return !reqd || current?.natures?.some(({ nature }) => reqd.includes(nature?.code));
    });

    const enabledOnes = compatibleApps.filter((item) => item.options.handles(current));
    const disabledOnes = compatibleApps.filter((item) => !enabledOnes.includes(item));

    return [
      ...enabledOnes.map((m) => ({ ...m, disabled: false })),
      ...disabledOnes.map((m) => ({ ...m, disabled: true })),
    ];
  }, [current, apps]);

  return currentApps;
}

export function useCurrentLanguage() {
  const [current, setCurrent] = useState(getLanguage);

  useEffect(() => {
    const sub = currentLanguage$.subscribe(setCurrent);
    return () => sub.unsubscribe();
  }, []);

  return current;
}
