import { setDefaultAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/highstock';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

import './icon-libs';
import './leaflet-config';

Highcharts.setOptions({
  chart: {
    zooming: {
      resetButton: { theme: { r: 8, fill: '#fff' } },
    },
    style: {
      fontFamily: 'Poppins',
    },
    spacing: [0, 0, 0, 0],
  },
  credits: { enabled: false },
  lang: {
    thousandsSep: "'",
    decimalPoint: '.',
  },
  legend: {
    itemHiddenStyle: {
      color: '#536471',
      opacity: 0.7,
      textDecoration: 'none',
    },
  },
  time: {
    useUTC: false,
  },
  title: { text: undefined },
  xAxis: {
    dateTimeLabelFormats: {
      hour: '%H:%M',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y',
    },
  },
});

HighchartsAccessibility(Highcharts);

//Workaround to show the reset zoom button while using navigator
HighchartsStock.removeEvent(HighchartsStock.Chart, 'beforeShowResetZoom');

// Change the default animation for all dialogs
setDefaultAnimation('dialog.denyClose', {
  keyframes: [],
  options: {},
});

setBasePath(__webpack_public_path__);

export * from '@shoelace-style/shoelace/dist/react';
export * as Highcharts from 'highcharts';
export { default as HighchartsReact } from 'highcharts-react-official';
export { default as HighchartsMore } from 'highcharts/highcharts-more';
export { default as HighchartsStock } from 'highcharts/highstock';
export { default as HighchartsAccessibility } from 'highcharts/modules/accessibility';
export { default as HighchartsArcDiagram } from 'highcharts/modules/arc-diagram';
export { default as HighchartsBoost } from 'highcharts/modules/boost';
export { default as HighchartsBoostCanvas } from 'highcharts/modules/boost-canvas';
export { default as HighchartsData } from 'highcharts/modules/data';
export { default as HighchartsDrilldown } from 'highcharts/modules/drilldown';
export { default as HighchartsExporting } from 'highcharts/modules/exporting';
export { default as HighchartsHeatmap } from 'highcharts/modules/heatmap';
export { default as HighchartsNetworkGraph } from 'highcharts/modules/networkgraph';
export { default as HighchartsSankey } from 'highcharts/modules/sankey';
export { default as HighchartsTimeline } from 'highcharts/modules/timeline';
export { default as HighchartsTreeGraph } from 'highcharts/modules/treegraph';
export { default as HighchartsTreeMap } from 'highcharts/modules/treemap';
export * as Leaflet from 'leaflet';
export { default as Lottie } from 'lottie-react';
export * from 'react-leaflet';
export * from './ActionButtons';
export * from './AddressAutocomplete';
export * from './Autocomplete';
export * from './Breadcrumbs';
export * from './Card';
export * from './ConfigureApp';
export * from './DateRangePicker';
export * from './EnergyChart';
export * from './FileUpload';
export * from './Heading';
export * from './hooks';
export * from './Icon';
export * from './Illustrations';
export * from './LeftLabelledInput';
export * from './Logo';
export * from './MaskedInput';
export * from './NavButton';
export * from './NavTab';
export * from './Pagination';
export * from './Prompt';
export * from './Stats';
export * from './StepperWizard';
export * from './Tab';
export * from './Table';
export * from './TimeFilter';
export * from './types';
export * from './UOMInput';
export * from './utils';
