import { getRootTrail } from '@co4/graph';

export const DEFAULT_APP = 'gi'; // General Information
const APPS = ['gi', 'monitoring', 'thingsboard', 'zev'];

const fixRoute = (route: string) => {
  return route.replace(/\/\//g, '/').replace(/\/$/, '');
};

export const objectsRootPath = '/objects';
export const objectsTrailPath = (trail = ':trail') => `${objectsRootPath}/${trail}`;
export const objectsArgsPath = (app = ':app', trail = ':trail') => `${objectsTrailPath(trail)}/${app}`;
export const objectsArgsSubPath = (app = ':app', trail = ':trail', tab = ':tab?', id = ':id?') =>
  `${objectsArgsPath(app, trail)}/${tab}/${id}`;

//temporary transitioning route for DM2. Remove once app use is stable.
export const objectsDetailsAppPath = `${objectsRootPath}/details/:app?/:trail?/:tab?/:id?`;

// transition route helper
export const transitioningRoute = (params) => {
  let { app, trail = getRootTrail(), tab = '', id = '' } = params;

  if (!APPS.includes(app)) {
    const ids = trail?.includes('-') ? trail : getRootTrail();
    return objectsArgsSubPath(DEFAULT_APP, ids);
  }

  app = app?.replace(/^:.*/g, DEFAULT_APP);
  trail = trail?.replace(/^:.*/g, getRootTrail());
  tab = tab?.replace(/^:.*/g, '');
  id = id?.replace(/^:.*/g, '');

  let path = fixRoute(objectsArgsSubPath(app, trail, tab, id));

  return path;
};
