import { Property } from './types';

/**
 * Deep Compare two objects or arrays
 * @param x First object or array
 * @param y Second object or array
 * @returns `true` if the objects or arrays are equal, `false` otherwise
 */
export function deepCompare(x: any, y: any) {
  if (x === y) {
    return true;
  } else if (
    typeof x === 'object' &&
    x != null &&
    typeof y === 'object' &&
    y != null &&
    Object.keys(x).length === Object.keys(y).length
  ) {
    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepCompare(x[prop], y[prop])) return false;
      } else return false;
    }
    return true;
  }

  return false;
}

/**
 * Transform an array of properties into an object
 * @param properties Array of properties to transform. Each property should have a `label` and a `value` property
 * @returns Object with the properties as key-value pairs
 */
export function transformProperties(properties: Array<Property>) {
  return properties.reduce((obj, prop) => {
    obj[prop.label] = prop.value;
    return obj;
  }, {} as Record<string, Property[keyof Property]>);
}
