import { registerIconLibrary } from '@shoelace-style/shoelace';
import icons from './icons';

function getIconFolder(name: string) {
  if (name.substring(0, 4) === 'fas-') {
    return 'solid';
  } else if (name.substring(0, 4) === 'fab-') {
    return 'brands';
  } else {
    return 'regular';
  }
}

registerIconLibrary('fa', {
  resolver: (name) => {
    const filename = name.replace(/^fa[rbs]-/, '');
    const folder = getIconFolder(name);
    return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.6.0/svgs/${folder}/${filename}.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('lucide', {
  resolver: (name) => `https://cdn.jsdelivr.net/npm/lucide-static@0.445.0/icons/${name}.svg`,
});

registerIconLibrary('unicons', {
  resolver: (name) => {
    const match = name.match(/^(.*?)(-s)?$/);
    return `https://cdn.jsdelivr.net/npm/@iconscout/unicons@3.0.3/svg/${match?.[2] === '-s' ? 'solid' : 'line'}/${
      match?.[1]
    }.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('boxicons', {
  resolver: (name) => {
    let folder = 'regular';
    if (name.substring(0, 4) === 'bxs-') folder = 'solid';
    if (name.substring(0, 4) === 'bxl-') folder = 'logos';
    return `https://cdn.jsdelivr.net/npm/boxicons@2.0.5/svg/${folder}/${name}.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('material', {
  resolver: (name) => {
    const match = name.match(/^(.*?)(_(round|sharp))?$/);

    if (match) {
      return `https://cdn.jsdelivr.net/npm/@material-icons/svg@1.0.33/svg/${match[1]}/${match[3] || 'outline'}.svg`;
    }

    return '';
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('core', {
  resolver: (name) => icons[name] || '',
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});
