import { SlCheckbox, SlIcon } from '@shoelace-style/shoelace/dist/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../Pagination';
import { getNestedValue } from '../utils';
import { SubRowProps } from './SubRow';
import TableBodyRow from './TableBodyRow';

export interface ColumnData<T extends Record<string, any> = {}> {
  header: string;
  field: string;
  id: string;
  sort?: boolean;
  width?: number;
  renderer?: (data: T) => React.ReactNode;
  headerRenderer?: (data: Pick<ColumnData<T>, 'field' | 'sort' | 'header' | 'id'>) => React.ReactNode;
  show?: boolean;
}

export interface TableProps<T> {
  data: Array<T & { subRows?: Array<T> }>;
  columns: Array<ColumnData<T>>;
  rowSelection?: boolean;
  onSelectionChange?: (data: Array<T>) => void;
  noDataText: string;
  rowsPerPage?: number;
  variant?: 'filled' | 'outlined';
  expandable?: boolean;
  subrowProps?: SubRowProps;
}

const uuid = () => Math.random().toString(16).slice(-8);

const TableComponent = <T extends Record<string, any>>(props: TableProps<T>) => {
  const locale = localStorage.getItem('co4:app/language') ?? 'de';
  const [t] = useTranslation();
  const {
    data: ogData,
    columns,
    rowSelection = false,
    onSelectionChange = () => {},
    noDataText = t('noRecordsFound'),
    rowsPerPage = 10,
    variant = 'outlined',
    expandable = false,
  } = props;
  const [selection, setSelection] = React.useState<Array<string>>([]);
  const [sort, setSort] = React.useState({ field: columns?.[0]?.field ?? '', order: 'asc' });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    rowsPerPage,
    totalPages: 1,
  });

  const data: Array<T & { selectionId?: string }> = React.useMemo(() => {
    setPagination(({ rowsPerPage }) => ({
      currentPage: 1,
      rowsPerPage,
      totalPages: Math.ceil(data.length / rowsPerPage),
    }));

    return ogData.map((d, i) => ({ ...d, selectionId: uuid() }));
  }, [ogData]);

  const currentRange = React.useMemo(() => {
    const start = (pagination.currentPage - 1) * pagination.rowsPerPage;
    const end = start + pagination.rowsPerPage > data.length ? data.length : start + pagination.rowsPerPage;
    return { start, end };
  }, [pagination]);

  const sortBy = React.useCallback(
    (a, b) => {
      const { field, order } = sort;
      if (!field) return 0;
      const valA = getNestedValue(a, field);
      const valB = getNestedValue(b, field);
      const multiplier = order === 'asc' ? 1 : -1;

      if (typeof valA === 'number' && typeof valB === 'number') {
        return order === 'asc' ? valA - valB : valB - valA;
      } else if (typeof valA === 'string' && typeof valB === 'string') {
        return multiplier * valA?.localeCompare(valB, locale);
      } else return 0;
    },
    [sort],
  );

  const currentPageData = React.useMemo(() => {
    return data.sort(sortBy).slice(currentRange.start, currentRange.end);
  }, [pagination, data, sortBy]);

  const handleHeaderClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const { id: field } = e.target as HTMLHeadElement;
      const order = sort.order === 'asc' ? 'desc' : 'asc';

      if (field === sort.field) {
        setSort((prev) => ({ ...prev, order }));
      } else {
        setSort({ field, order: 'asc' });
      }
    },
    [sort],
  );

  const handleSelectionChange = (id: string | null, all?: boolean) => {
    let updatedSelections = [...selection];

    if (all !== undefined) {
      updatedSelections = all ? currentPageData.map((d) => d.selectionId!) : [];
    } else if (id !== null) {
      if (updatedSelections.includes(id)) {
        updatedSelections.splice(updatedSelections.indexOf(id), 1);
      } else {
        updatedSelections.push(id);
      }
    }

    const selectionData = all
      ? [...data]
      : updatedSelections.map((sel) => data.find((d) => d.selectionId === sel)!).filter(Boolean);

    onSelectionChange(selectionData as T[]);
    setSelection(updatedSelections);
  };

  let areAllSelected =
    rowSelection && selection.length !== 0 && currentPageData.every((d) => selection.includes(d.selectionId!));

  const onPageChange = (currentPage: number) => {
    setPagination((prev) => ({ ...prev, currentPage }));
  };

  const onRowsChange = (rowsPerPage: number) => {
    setPagination({
      currentPage: 1,
      rowsPerPage,
      totalPages: Math.ceil(data.length / rowsPerPage),
    });
  };

  return (
    <>
      <div className={`table-wrapper ${variant}`}>
        <table>
          <thead>
            <tr>
              {rowSelection && (
                <th id="select-all-box">
                  <div id="select-all" className="head-col selection-box ">
                    <SlCheckbox
                      disabled={!data.length}
                      checked={areAllSelected}
                      onSlChange={() => {
                        handleSelectionChange(null, !areAllSelected);
                      }}></SlCheckbox>
                  </div>
                </th>
              )}
              {expandable && <th className="expand-button-td"></th>}

              {columns.map((col) => {
                const { id, field, header, headerRenderer, width = 'auto', sort: colSort = true, show } = col;
                const icon = sort.field === field && sort.order === 'desc' ? 'sort-alpha-down-alt' : 'sort-alpha-down';

                if (show === false) return null;

                return (
                  <th key={id} id={field} style={{ width }} onClick={colSort ? handleHeaderClick : () => {}}>
                    {headerRenderer ? (
                      headerRenderer({ field, header, id, sort: colSort })
                    ) : (
                      <div id={field} className="head-col" data-value={header}>
                        {header}
                        {colSort && <SlIcon name={icon}></SlIcon>}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              currentPageData.map((item, index) => (
                <TableBodyRow
                  key={`row-${item?.id}`}
                  expandable={expandable}
                  rowSelection={rowSelection}
                  selected={selection.includes(item.selectionId!)}
                  onCheckboxToggle={handleSelectionChange}
                  columns={columns}
                  subrowProps={props?.subrowProps ?? ({} as SubRowProps)}
                  index={index}
                  data={item}
                />
              ))
            ) : (
              <tr>
                <td colSpan={columns.length + +rowSelection}>{noDataText}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {(pagination.totalPages > 1 || pagination.rowsPerPage !== rowsPerPage) && (
        <Pagination
          currentRange={currentRange}
          totalData={data.length}
          totalPages={pagination.totalPages}
          rowsPerPage={pagination.rowsPerPage}
          onPageChange={onPageChange}
          onRowsChange={onRowsChange}
          activePage={pagination.currentPage}
        />
      )}
    </>
  );
};

export const Table = React.memo(TableComponent) as typeof TableComponent;
