import React from 'react';
import { SlDialog, SlIcon, SlIconButton, SlMenu, SlMenuItem } from '@shoelace-style/shoelace/dist/react';
import type { SlMenu as HTMLMenu } from '@shoelace-style/shoelace';
import { useOnClickOutside, useDebouncedCallback } from '../hooks';
import { queryLocationByCoords, queryOsmId, queryPosition } from './api';
import { Address } from './types';
import { MapSelection } from './MapSelection';

export interface AddressAutocompleteProps {
  onChange: (value: Address) => void;
  value?: string;
  className?: string;
  name?: string;
  id?: string;
  label?: string;
  helperText?: string;
  readOnly?: boolean;
  osmId?: string;
  showMap?: boolean;
}

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = (props) => {
  const {
    onChange,
    value,
    label,
    helperText,
    className = '',
    readOnly = false,
    osmId,
    showMap = true,
    ...rest
  } = props;
  const [addresses, setAddresses] = React.useState<Array<Address>>([]);
  const [inputValue, setInputValue] = React.useState(value ?? '');
  const menuRef = React.useRef<HTMLMenu>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState<{ lat: number; lng: number }>();

  const hideMenu = () => {
    if (menuRef.current) {
      menuRef.current.style.display = 'none';
    }
  };

  useOnClickOutside(wrapperRef, hideMenu);

  const changeValue = (value: Address) => {
    const { address } = value;

    const formatted = {
      ...address,
      country: address?.country || address?.country_code || '',
      zip: address?.postcode || address?.address29 || address?.postal_code || '',
      city:
        address?.city ||
        address?.town ||
        address?.village ||
        address?.hamlet ||
        address?.locality ||
        address?.municipality ||
        address?.city_district ||
        '',
      house: address?.house_number || '',
      road:
        address?.road ||
        address?.footway ||
        address?.street ||
        address?.pedestrian ||
        address?.highway ||
        address?.path ||
        '',
      region:
        address?.state ||
        address?.state_district ||
        address?.county ||
        address?.province ||
        address?.region ||
        address?.district ||
        address?.suburb ||
        address?.neighbourhood ||
        address?.quarter ||
        address?.subdivision ||
        '',

      building: address?.building || address?.amenity || address?.building_name || address?.public_building || '',
      state_district: address?.state_district || address?.county || address?.state || address?.district || '',
    };

    onChange({ ...value, address: formatted });
  };

  const getAddressList = useDebouncedCallback((text: string) => {
    queryPosition(text)
      .then((res) => {
        setAddresses(res);

        if (res.length && menuRef.current) {
          menuRef.current.style.display = '';
        }
      })
      .catch((err) => console.error(err));
  });

  const getAddressById = () => {
    if (osmId?.match(/^[NSW]/)) {
      queryOsmId(osmId)
        .then(([res]) => {
          setInputValue(res.display_name);
          setPosition({ lat: Number(res.lat), lng: Number(res.lon) });
        })
        .catch((err) => console.error(err));
    }
  };

  const getAddressByCoords = ({ lat, lng }: { lat: number; lng: number }) => {
    setPosition({ lat, lng });

    queryLocationByCoords(lat, lng).then((res) => {
      setInputValue(res.display_name);
      changeValue(res);
    });
  };

  React.useEffect(getAddressById, [osmId]);

  React.useEffect(() => {
    if (!inputValue) {
      setInputValue(value ?? '');
    }
  }, [value]);

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: search } = evt.target;
    setInputValue(search);
    getAddressList(search);
  };

  const onAddressSelection = (value: Address) => {
    changeValue(value);
    setInputValue(value.display_name);
  };

  return (
    <>
      <div className={`address-autocomplete ${className}`} {...rest} ref={wrapperRef}>
        {label !== undefined && (
          <label className="sub-heading" htmlFor={rest.id}>
            {label}
          </label>
        )}
        <div className="address-input-row">
          <input
            readOnly={readOnly}
            onChange={onInputChange}
            type="text"
            className="address-input"
            value={inputValue}
          />
        </div>
        {helperText && <span className="helper-text">{helperText}</span>}
        <SlMenu ref={menuRef} onSlSelect={hideMenu} style={{ display: 'none' }}>
          {addresses.map(({ display_name, osm_id, ...rest }) => (
            <SlMenuItem key={osm_id} onClick={() => onAddressSelection({ display_name, osm_id, ...rest })}>
              {display_name}
            </SlMenuItem>
          ))}
        </SlMenu>
      </div>

      {showMap && <MapSelection position={position} onPositionChange={getAddressByCoords} />}
    </>
  );
};
