import { LatLngLiteral, Map, Marker as TypeMarker } from 'leaflet';
import { useEffect, useMemo, useRef } from 'react';
import { MapContainer, Marker, TileLayer, useDebouncedCallback } from '../index';

import 'leaflet/dist/leaflet.css';

interface MapSelectionProps {
  position?: LatLngLiteral;
  onPositionChange: (position?: LatLngLiteral) => void;
}

export const MapSelection: React.FC<MapSelectionProps> = ({ position, onPositionChange }) => {
  const markerRef = useRef<TypeMarker>(null);
  const mapRef = useRef<Map>(null);
  const center = useMemo(() => mapRef.current?.getCenter(), [mapRef.current?.getCenter()]);

  const resetCenterOnZoom = useDebouncedCallback(() => {
    mapRef?.current?.setView(markerRef.current.getLatLng());
  });

  useEffect(() => {
    mapRef.current?.on('move', (e) => {
      if (e?.sourceTarget?._moved === true) {
        const newCenter = mapRef.current.getCenter();
        markerRef.current.setLatLng(newCenter);
        changePosition(newCenter);
      }
    });

    mapRef?.current?.on('zoomend', resetCenterOnZoom);

    return () => {
      mapRef.current?.off('zoomend');
      mapRef.current?.off('move');
    };
  }, [mapRef?.current]);

  const changePosition = useDebouncedCallback(onPositionChange, 750);

  useEffect(() => {
    if (!position) {
      navigator.geolocation.getCurrentPosition((position) => {
        changePosition({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    } else if (position?.lat !== center?.lat || position?.lng !== center?.lng) {
      mapRef.current.setView(position);
      markerRef.current.setLatLng(position);
    }

    // workaround for leaflet map not rendering properly
    window.dispatchEvent(new Event('resize'));
  }, [position]);

  return (
    <div className="map-selection-control">
      <MapContainer
        ref={mapRef}
        key="map-selection"
        center={center ?? { lat: 0, lng: 0 }}
        minZoom={3}
        zoom={10}
        worldCopyJump
        scrollWheelZoom>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {center && <Marker ref={markerRef} position={center} />}
      </MapContainer>
    </div>
  );
};
